import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { animateScroll as scroll} from 'react-scroll';
import './Subway.css';
import Table from './Table.js';
import ResultBox from './ResultBox.js';
import headerImg from './assets/img/Subway/subway-header-img.png';
import SimilarityImg1 from './assets/img/Subway/similarity-1.png';
import SimilarityImg2 from './assets/img/Subway/similarity-2.png';
import SimilarityImg3 from './assets/img/Subway/similarity-3.png';
import popup1 from './assets/img/Subway/popup-1.png';
import popup2 from './assets/img/Subway/popup-2.png';
import circle from './assets/img/Subway/circle.png';
import UserTestSetupDark from './assets/img/Subway/user-test-setup-dark.png';
import UserTestSetupLight from './assets/img/Subway/user-test-setup-light.png';
import BehavioralMappingVideo from './assets/video/behavioral-mapping.mp4';
import ContextualInquiry from './assets/img/Subway/contextual-inquiry.png';

function Subway() {

    const [theme, setTheme] = useState('dark');
    const [atBottom, setAtBottom] = useState(false);
    const [activeSection, setActiveSection] = useState('');
    const navigate = useNavigate();
    const location = useLocation();
  
    useEffect(() => {
      document.documentElement.setAttribute('data-theme', theme);
    }, [theme]);
  
    const setLightMode = () => {
      setTheme('light');
    };
  
    const setDarkMode = () => {
      setTheme('dark');
    };
  
    useEffect(() => {
      const handleScroll = () => {
        const sections = ['#project-summary', '#problem', '#research', '#ideation', '#solution', '#evaluation'];
  
        let currentSection = '';
  
        sections.forEach(section => {
          const element = document.querySelector(section);
          const rect = element.getBoundingClientRect();
  
          // Detect when the section is in the viewport, at least partially visible
          if (rect.top >= 0 && rect.bottom <= window.innerHeight) {
            currentSection = section;
          } else if (rect.top < window.innerHeight && rect.bottom >= 0) {
            currentSection = section;
          }
        });
  
        setActiveSection(currentSection);
  
        // Check if user is at the bottom of the page
        if (window.innerHeight + window.pageYOffset >= document.body.offsetHeight) {
          setAtBottom(true);
        } else {
          setAtBottom(false);
        }
      };
  
      window.addEventListener('scroll', handleScroll);
      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }, []);
  
    const scrollToTop = () => {
      scroll.scrollToTop({
        duration: 400,
        smooth: 'easeInOutQuart',
      });
    };
  
    useEffect(() => {
      const hash = location.hash;
      if (hash) {
        const element = document.querySelector(hash);
        if (element) {
          const offset = 40;
          const elementPosition = element.getBoundingClientRect().top + window.pageYOffset;
          const offsetPosition = elementPosition - offset;
  
          window.scrollTo({
            top: offsetPosition,
            behavior: 'smooth',
          });
        }
      }
    }, [location]);

    return (
        <div>
            <div class = "body">
                <div class = "left-column">
                    <nav>
                        <ul class = {`shortcut-menu ${atBottom ? 'hide' : 'show'}`}>
                        <li class = {`left-column-list-text ${activeSection === '#project-summary' ? 'active' : ''}`}>
                            <Link to = "/subway#project-summary" smooth={true} duration={500}>project summary ↘</Link>
                        </li>
                        <li class = {`left-column-list-text ${activeSection === '#problem' ? 'active' : ''}`}>
                            <Link to = "/subway#problem" smooth={true} duration={500}>problem ↘</Link>
                        </li>
                        <li class = {`left-column-list-text ${activeSection === '#research' ? 'active' : ''}`}>
                            <Link to = "/subway#research" smooth={true} duration={500}>research ↘</Link>
                        </li>
                        <li class = {`left-column-list-text ${activeSection === '#ideation' ? 'active' : ''}`}>
                            <Link to = "/subway#ideation" smooth={true} duration={500}>analogous domain ↘</Link>
                        </li>
                        <li class = {`left-column-list-text ${activeSection === '#solution' ? 'active' : ''}`}>
                            <Link to = "/subway#solution" smooth={true} duration={500}>solution ↘</Link>
                        </li>
                        <li class = {`left-column-list-text ${activeSection === '#evaluation' ? 'active' : ''}`}>
                            <Link to = "/subway#evaluation" smooth={true} duration={500}>user test ↘</Link>
                        </li>
                        </ul>
                        {atBottom && (
                        <ul class = "shortcut-menu show">
                            <li class = "left-column-list-text" onClick = {scrollToTop} style = {{ cursor: 'pointer' }}>
                                Go to top ↑
                            </li>
                        </ul>
                        )}
                    </nav>
                    <div class = "theme-toggle">
                        <span onClick={setDarkMode} style={{ cursor: 'pointer', fontWeight: theme === 'dark' ? 'bold' : 'normal', color: theme === 'dark' ? 'inherit' : '#929292' }}>⏾ DARK</span>
                        <span style={{ margin: '0 10px' }}> / </span>
                        <span onClick={setLightMode} style={{ cursor: 'pointer', fontWeight: theme === 'light' ? 'bold' : 'normal', color: theme === 'light' ? 'inherit' : '#929292' }}>☼ LIGHT</span>
                    </div>
                </div>
                <div class = "main-content">
                    <p class = "project-subtitle" id = "project-summary">Senior Thesis</p>
                    <h1 class = "project-title">Reducing Seat-Finding Frustrations in the Subway</h1>
                    <div class = "header-img">
                        <img src={headerImg} alt="Header Image" />
                    </div>
                    <p class = "project-description">For my senior thesis, I examined the inefficiency and unpredictability of finding seats in subway cabins and its effect on passenger sentiments. As a solution, I proposed a notification system with buttons and LED lights to signal upcoming vacant seats. A user test of a low-fidelity prototype suggested that it has had a positive impact in decreasing the difficulty and inconvenience of seat-finding within the subway.</p>
                    <div class = "project-tag-list">
                        <div class = "project-tag-row-1">
                            <div class = "project-tag-item" id = "timeline">
                                <p class = "tag-name">TIMELINE</p>
                                <p class = "tag-description">6 months<br></br>(Fall 2022)</p>
                            </div>
                            <div class = "project-tag-item" id = "role">
                                <p class = "tag-name">ROLE</p>
                                <p class = "tag-description">Researcher <br></br> Designer</p>
                            </div>
                        </div>
                        <div class = "project-tag-row-2">
                            <div class = "project-tag-item" id = "collaborators">
                                <p class = "tag-name">COLLABORATORS</p>
                                <p class = "tag-description">Solo Project <br></br>Advised by Soojin Jun</p>
                            </div>
                            <div class = "project-tag-item" id = "tools">
                                <p class = "tag-name">TOOLS</p>
                                <p class = "tag-description">SketchUp</p>
                            </div>
                        </div>
                        <div class = "project-tag-row-3">
                        </div>
                    </div>
                    <section class = "content-section" id = "problem">
                        <p class = "content-tag">01 PROBLEM</p>
                        <div class = "section-content">
                            <p class = "section-subtitle" id = "problem-statement">The current method of finding seats in subway cabins is disorganized, inefficient, and largely relies on chance.</p>
                            <p class = "body-text">An unspoken rule often applies in subway cabins: the person standing directly in front of a seat gets to take it once the seated passenger leaves, regardless of how long they've been standing. As a result, someone may have to wait a long time to sit if they are unlucky enough to stand in front of a passenger who is traveling a long distance. </p>
                        </div>
                    </section>
                    <section class = "content-section" id = "research">
                        <p class = "content-tag">02 RESEARCH</p>
                        <div class = "section-content">
                            <p class = "section-subtitle" id = "research-question">What does the current subway seating process look like?</p>
                            <p class = "body-text">I first wanted to get a clear picture of how passengers currently navigate seat-finding in subway cabins. In order to get a better understanding of their behaviors and patterns, here are the research techniques that I employed to gather insights:</p>
                            <div class = "method-box-wrapper">
                                <div class = "method-box">
                                    <p class = "box-title">METHOD #1: <br></br> BEHAVIORAL MAPPING</p>
                                    <p class = "box-text">Behavioral mapping was conducted to map the flow of movements of people within the cabin. About 24 minutes of the movements within cabin 1-1 in Subway Line 7 were mapped in a video format. </p>
                                </div>
                                <div class = "method-box">
                                    <p class = "box-title">METHOD #2: <br></br> FLY-ON-THE-WALL</p>
                                    <p class = "box-text">The fly-on-the-wall method were carried out in Lines 2, 7, and 5, and the researcher observed passenger behavior and took notes on the cell phone, sitting down as a passenger in the cabin.</p>
                                </div>
                            </div>
                            <div class = "finding-section">
                                <p class = "box-title" id = "finding-tag">FINDING #1</p>
                                <p class = "italic-text" id = "finding-statement"><i>“71.43% of individuals who sat down waited longer than at least one person who boarded after them.”</i></p>
                                <p class = "body-text" id = "finding-text">An analysis of wait times through behavioral mapping indicated that a significant portion of passengers are subject to longer wait times due to the arbitrary nature of seating arrangements. Also, through the fly-on-the-wall method, I observed that even when seats became available in the back, passengers frequently failed to notice them.</p>
                            </div>
                            <div class = "behavioral-mapping-video-wrapper">
                                <video id = "behavioral-mapping-video" autoPlay loop muted playsInline>
                                    <source src= {BehavioralMappingVideo} type="video/mp4" />
                                </video>
                            </div>
                            <p class = "section-subtitle">What do passengers feel about seat-finding within the subway cabin?</p>
                            <p class = "body-text">After confirming that seat-finding in subway cabins largely relies on chance, I set out to understand more about passenger behavior and sentiments through the following methods:</p>
                            <div class = "method-box-wrapper">
                                <div class = "method-box">
                                    <p class = "box-title">METHOD #3: <br></br> CONTEXTUAL INQUIRY</p>
                                    <p class = "box-text">A contextual inquiry was conducted with two participants: one woman in her fifties (A1) and one woman in her twenties (A2). I observed and filmed their subway journeys, capturing interactions, processes, and behaviors in their natural environment. Discussions were initiated for actions that needed clarification or interpretation, such as decision-making processes and seat preferences.</p>
                                </div>
                                <div class = "method-box">
                                    <p class = "box-title">METHOD #4: <br></br> INTERVIEWS</p>
                                    <p class = "box-text">Interviews were conducted with ten participants, including eight frequent users of the Seoul Metropolitan Subway and two exchange students new to the system. Participants from diverse age groups and both genders discussed subway usage habits, seat preferences, commuting challenges, and their sentiments and behaviors related to finding seats within the subway cabin.</p>
                                </div>
                            </div>
                            <div class = "finding-section" id = "finding-section2">
                                <p class = "box-title" id = "finding-tag">FINDING #2</p>
                                <p class = "italic-text" id = "finding-statement"><i>“For example, in the case of Line 7, I don’t stand in front of people wearing hiking clothes because they’re probably going a long distance.”</i> -Contextual Inquiry Participant 1</p>
                                <p class = "body-text" id = "finding-text">Interviewees who regularly took the same route for their commute relied on accumulated knowledge of station patterns, paying attention to visual cues. Some also mentioned listening for verbal cues, such as overhearing passengers on the phone talking about when they would get off the train.</p>
                            </div>
                            <div class = "finding-section" id = "finding-section3">
                                <p class = "box-title" id = "finding-tag">FINDING #3</p>
                                <p class = "italic-text" id = "finding-statement"><i>“I get so frustrated when someone who came in later than me takes a seat before me, especially when I am tired.”</i> -Interviewee 5</p>
                                <p class = "body-text" id = "finding-text">When asked about sentiments about seat-finding in the subway, negative feelings such as annoyance, frustration, and envy were associated with such experiences. However, one interesting finding was that not many interviewees perceived it as a problem that could be or should be solved. They thought it was just a problem of luck.</p>
                            </div>
                            <p class = "section-subtitle">Insights</p>
                            <p class = "body-text">1. Finding seats in subway cabins indeed has an arbitrary nature, with availability often left to chance, creating uncertainty for passengers.</p>
                            <p class = "body-text">2. Though passengers don’t view the seat-finding process as a solvable problem—treating it as a social norm—they still associate it with negative emotions.</p>
                            <p class = "body-text">3. Passengers rely on visual and verbal cues to predict seat availability due to the lack of real-time information on soon-to-be vacant seats.</p>
                        </div>
                    </section>
                    <section class = "content-section" id = "ideation">
                        <p class = "content-tag">04 ANALOGOUS DOMAIN</p>
                        <div class = "section-content">
                            <p class = "body-text">Building on insights from my research, I decided to explore analogous domains to reframe challenges and find new ways to address user needs. Specifically, I thought that parking spot searches was a domain that shares similar pain points with the problem that I am trying to solve in the following ways:</p>
                            <div id = "similarity-wrapper">
                                <div id = "similarity-item">
                                    <img class = "similarity-img" src={SimilarityImg1} />
                                    <p class = "similarity-text" id = "similarity-title">Limited Availability</p>
                                    <p class = "similarity-text">Both parking spots and subway seats are limited resources, leading to competition and frustration due to uncertainty in availability.</p>
                                </div>
                                <div id = "similarity-item">
                                    <img class = "similarity-img" src={SimilarityImg2} />
                                    <p class = "similarity-text" id = "similarity-title">Social Rules</p>
                                    <p class = "similarity-text">In both contexts, unwritten rules suggest that the person closest to the spot or seat claims it when it becomes available, regardless of how long others have waited.</p>
                                </div>
                                <div id = "similarity-item">
                                    <img class = "similarity-img" src={SimilarityImg3} />
                                    <p class = "similarity-text" id = "similarity-title">Lack of Real-Time Information</p>
                                    <p class = "similarity-text">Drivers and subway passengers are often unaware of when a spot/seat will become available, relying on visual cues or chance rather than systematic guidance.</p>
                                </div>
                            </div>
                            <p class = "body-text">Given that solutions that work in one domain might be optimized and adapted to solve similar pain points in another, I examined some existing solutions that have tried to solve the problem of parking spot searches and analyzed their applicability to seat-finding in the subway.</p>
                            <Table />
                        </div>
                    </section>
                    <section class = "content-section" id = "solution">
                        <p class = "content-tag">05 SOLUTION</p>
                        <div class = "section-content">
                            <p class = "body-text">Based on insights from analogous domains, I proposed a design solution of a notification system to indicate soon-to-be-vacant seats. Seated passengers can press the foot button up to three stops in advance to notify others they are getting off. The light turns red when occupied, green when the button is pressed or when the seat is vacated.</p>
                            <p class = "body-text">This system helps passengers identify seats that will soon be available, reducing the uncertainty and the factor of chance in seat-finding. It's easy to use, without the need to download additional apps, entirely voluntary and doesn’t require sharing additional personal information.</p>
                            <p class = "body-text">Below is a visualization of the design, modeled in SketchUp. Hover over the <span id = "blue-dot-text">blue dots</span> for more details:</p>
                            <div class = "vizimg">
                                <div class = "viz">
                                    <div class = "info1-wrapper">
                                        <div class = "info1">
                                            <div class = "circle1-wrapper">
                                                <img class = "circle1" src={circle} />
                                                <div class = "popup">
                                                    <img class = "popup" src={popup1} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class = "info2-wrapper">
                                        <div class = "info2">
                                            <div class = "circle2-wrapper">
                                                <img class = "circle2" src={circle} />
                                                <div class = "popup2">
                                                    <img class = "popup2" src={popup2} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section class = "content-section" id = "evaluation">
                        <p class = "content-tag">06 USER TESTING</p>
                        <div class = "section-content">
                            <p class = "body-text">To test the effectiveness of the solution, I rented a small theater, set up a low-fidelity prototype of a subway cabin, and recruited 18 participants to simulate passengers on a subway journey of nine stations.</p>
                            <p class = "image-subtext" id = "test-setup-subtext">User Test Setup</p>
                            <img class = "user-test-setup" src={theme === 'light' ? UserTestSetupLight : UserTestSetupDark}/>
                            <p class = "section-subtitle">Results</p>
                            <ResultBox />
                        </div>
                    </section>
                </div>
            </div>
            <div class = "project-footer">
                <div class = "project-footer-top">
                    <div class = "project-footer-previous">
                        <p class = "project-footer-subtitle">Focus Planet</p>
                        <p class = "project-footer-title">Building a Product to Starting a Startup</p>
                        <button class = "read-more-button" onClick={() => navigate('/focusplanet')}>← Read More</button>
                    </div>
                    <div class = "project-footer-next">
                        <p class = "project-footer-subtitle">3i Inc.</p>
                        <p class = "project-footer-title">Designing at the Intersection of Hardware and Software</p>
                        <button class = "read-more-button" onClick={() => navigate('/pivo')}>Read More →</button>
                    </div>
                </div>
            <div class = "divider"></div>
            </div>
        </div>
  );

}

export default Subway;