import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { animateScroll as scroll} from 'react-scroll';
import './Pivo.css';
import headerImg from './assets/img/Pivo/pivo-header-img.png';
import pivoIntroImg from './assets/img/Pivo/pivo-intro-img.png';
import pivoLogo from './assets/img/Pivo/pivo-icon.png';
import pivoStudioImg from './assets/img/Pivo/pivo-studio-img.png';
import pivoLiveImg from './assets/img/Pivo/pivo-live-img.png';

function Pivo() {

    const [theme, setTheme] = useState('dark');
    const [atBottom, setAtBottom] = useState(false);
    const [activeSection, setActiveSection] = useState('');
    const navigate = useNavigate();
    const location = useLocation();
  
    useEffect(() => {
      document.documentElement.setAttribute('data-theme', theme);
    }, [theme]);
  
    const setLightMode = () => {
      setTheme('light');
    };
  
    const setDarkMode = () => {
      setTheme('dark');
    };
  
    useEffect(() => {
      const handleScroll = () => {
        const sections = ['#project-summary', '#context', '#contribution', '#reflection'];
  
        let currentSection = '';
  
        sections.forEach(section => {
          const element = document.querySelector(section);
          const rect = element.getBoundingClientRect();
  
          // Detect when the section is in the viewport, at least partially visible
          if (rect.top >= 0 && rect.bottom <= window.innerHeight) {
            currentSection = section;
          } else if (rect.top < window.innerHeight && rect.bottom >= 0) {
            currentSection = section;
          }
        });
  
        setActiveSection(currentSection);
  
        // Check if user is at the bottom of the page
        if (window.innerHeight + window.pageYOffset >= document.body.offsetHeight) {
          setAtBottom(true);
        } else {
          setAtBottom(false);
        }
      };
  
      window.addEventListener('scroll', handleScroll);
      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }, []);
  
    const scrollToTop = () => {
      scroll.scrollToTop({
        duration: 400,
        smooth: 'easeInOutQuart',
      });
    };
  
    useEffect(() => {
      const hash = location.hash;
      if (hash) {
        const element = document.querySelector(hash);
        if (element) {
          const offset = 40;
          const elementPosition = element.getBoundingClientRect().top + window.pageYOffset;
          const offsetPosition = elementPosition - offset;
  
          window.scrollTo({
            top: offsetPosition,
            behavior: 'smooth',
          });
        }
      }
    }, [location]);

    return (
        <div>
            <div class = "body">
                <div class = "left-column">
                    <nav>
                        <ul className={`shortcut-menu ${atBottom ? 'hide' : 'show'}`}>
                            <li className={`left-column-list-text ${activeSection === '#project-summary' ? 'active' : ''}`}>
                                <Link to="/pivo#project-summary" duration={500}>project summary ↘</Link>
                            </li>
                            <li className={`left-column-list-text ${activeSection === '#context' ? 'active' : ''}`}>
                                <Link to="/pivo#context" duration={500}>context ↘</Link>
                            </li>
                            <li className={`left-column-list-text ${activeSection === '#contribution' ? 'active' : ''}`}>
                                <Link to="/pivo#contribution" duration={500}>contributions ↘</Link>
                            </li>
                            <li className={`left-column-list-text ${activeSection === '#reflection' ? 'active' : ''}`}>
                                <Link to="/pivo#reflection" duration={500}>reflection ↘</Link>
                            </li>
                        </ul>
                        {atBottom && (
                        <ul class = "shortcut-menu show">
                            <li class = "left-column-list-text" onClick = {scrollToTop} style = {{ cursor: 'pointer' }}>
                                Go to top ↑
                            </li>
                        </ul>
                        )}
                    </nav>
                    <div class = "theme-toggle">
                        <span onClick={setDarkMode} style={{ cursor: 'pointer', fontWeight: theme === 'dark' ? 'bold' : 'normal', color: theme === 'dark' ? 'inherit' : '#929292' }}>⏾ DARK</span>
                        <span style={{ margin: '0 10px' }}> / </span>
                        <span onClick={setLightMode} style={{ cursor: 'pointer', fontWeight: theme === 'light' ? 'bold' : 'normal', color: theme === 'light' ? 'inherit' : '#929292' }}>☼ LIGHT</span>
                    </div>
                </div>
                <div class = "main-content">
                    <p class = "project-subtitle" id = "project-summary">3i Inc.</p>
                    <h1 class = "project-title">Designing at the Intersection of Hardware and Software</h1>
                    <div class = "header-img">
                        <img src = {headerImg} alt = "Greenify Header Image" />
                    </div>
                    <p class = "project-description">During the summer of 2021, I interned at 3i Inc. as a UX designer intern, ensuring seamless integration between the auto-tracking camera pod Pivo and its accompanying digital app services. I created flowcharts and wireframes for Pivo Live, a live streaming service, and Pivo Studio, a tool for capturing interactive 360° product shots.</p>
                    <div class = "project-tag-list">
                        <div class = "project-tag-row-1">
                            <div class = "project-tag-item" id = "timeline">
                                <p class = "tag-name">TIMELINE</p>
                                <p class = "tag-description">3 Months <br></br>(July 2021 – October 2021)</p>
                            </div>
                            <div class = "project-tag-item" id = "role">
                                <p class = "tag-name">ROLE</p>
                                <p class = "tag-description">UX Designer Intern</p>
                            </div>
                        </div>
                        <div class = "project-tag-row-2">
                            <div class = "project-tag-item" id = "collaborators">
                                <p class = "tag-name">COLLABORATORS</p>
                                <p class = "tag-description">1 Senior UX Designer <br></br> 1 Project Manager <br></br> 2 UI Designers <br></br> Engineering Team</p>
                            </div>
                            <div class = "project-tag-item" id = "tools">
                                <p class = "tag-name">TOOLS</p>
                                <p class = "tag-description">Figma <br></br> draw.io<br></br> Confluence</p>
                            </div>
                        </div>
                        <div class = "project-tag-row-3" id = "button-wrapper">
                            <a href="https://apps.apple.com/us/app/pivo/id1567512653" target="_blank" rel="noopener noreferrer">
                                <button class = "button-text">Download App →</button>
                            </a>
                        </div>
                    </div>
                    <section class = "content-section" id = "context">
                        <p class = "content-tag">01 CONTEXT</p>
                        <div class = "section-content" id = "context-content">
                            <div class = "pivo-introduction">
                                <div class = "pivo-intro-left">
                                    <img id = "pivo-logo" src = {pivoLogo} alt = "Pivo Logo" />
                                    <p class = "body-text">Pivo is an AI-powered smartphone mount that converts your smartphone into an auto-tracking camera that follows you. Its advanced tracking modes—Face, Body, and Horse Tracking—allow it to follow anyone or anything with precision. With auto zoom capabilities, users can film from various distances while staying in focus and perfectly framed.</p>
                                </div>
                                <img id = "pivo-intro-img" src = {pivoIntroImg} alt = "Pivo Intro Img" />
                            </div>
                        </div>
                    </section>
                    <section class = "content-section" id = "contribution">
                        <p class = "content-tag">02 CONTRIBUTIONS</p>
                        <div class = "section-content" id = "contribution-content">
                            <p class = "body-text">As part of my role as a UX designer intern, I contributed to the development of several digital apps that accompany this physical device, focusing on creating a seamless integration between the hardware and software for an intuitive and user-friendly experience.</p>
                            <p class = "section-subtitle">Improving User Experience for Pivo Plus</p>
                            <p class = "body-text">At the beginning of my internship, I worked primarily on Pivo Plus, an app that brings together popular features like Auto Tracking and video calls, while introducing new functionality such as customizable presets. My responsibilities included enhancing the first-time user experience by designing a walkthrough, crafting a research plan for user testing, and ensuring clear, documented communication between product management and engineering teams to support seamless collaboration.</p>
                            <p class = "section-subtitle">Building New User Flows</p>
                            <p class = "body-text">During my internship, our team was tasked with developing two new apps: Pivo Live, a livestreaming service, and Pivo Studio, a tool for capturing interactive 360° product shots. Following a co-creation workshop with product managers and UI designers, where we aligned on user behaviors, motivations, and needs, I had the opportunity to take the lead in creating the user flows and wireframes for both apps. </p>
                            <div class = "feature-img-wrapper">
                                    <div id = "feature-img2-wrapper">
                                        <img id = "feature-img2" src = {pivoStudioImg} alt = "Wireframe for Pivo Studio" />
                                        <p class = "image-subtext">Wireframe for Pivo Studio</p>
                                    </div>
                                    <div id = "solution-img3-wrapper">
                                        <img id = "feature-img3" src = {pivoLiveImg} alt = "User Flow for Pivo Live" />
                                        <p class = "image-subtext">User Flow for Pivo Live</p>
                                    </div>
                            </div>
                            <p class = "section-subtitle">Impact</p>
                            <p class = "body-text">Although Pivo Live and Pivo Studio were not launched as standalone apps due to changes in the decision to merge all features into a new all-in-one app, Pivo Plus, the user flows I created for the live streaming function and 360° product shots were included in the product landscape.</p>
                        </div>
                    </section>
                    <div class = "nda-notice-section">
                        <div class = "divider" id = "nda-notice-divider1"></div>
                        <p class = "box-text">Although I can’t share the details of my project publicly, please contact me on <a href = "http://linkedin.com/in/claire-cheong-26631819a" class = "link" target="_blank" rel="noopener noreferrer">LinkedIn↗</a> or via <a href = "mailto:claire6001@hotmail.com" class = "link" target="_blank" rel="noopener noreferrer">Email↗</a> if you are interested in learning more about my experience.</p>
                        <div class = "divider" id = "nda-notice-divider2"></div>
                    </div>
                    <section class = "content-section" id = "reflection">
                        <p class = "content-tag">03 REFLECTION</p>
                        <div class = "section-content" id = "reflection-content">
                            <p class = "section-subtitle">Seeing My Designs Come to Life</p>
                            <p class = "body-text">This was the first time I saw my designs come to life. Before this experience, my work had largely been conceptual, with prototypes and presentations being the final deliverables. While these projects were meaningful, knowing that my designs were now reaching thousands of users filled me with a sense of purpose, making me wake up feeling excited to go to work everyday.</p>
                            <p class = "section-subtitle">Navigating Technical Feasibility and Effective Collaboration with Engineers</p>
                            <p class = "body-text">At the same time, I also came to understand the limits of reality. I was surprised by how often our designs faced pushback from the engineering team, forcing us to compromise, especially under tight deadlines.While I had learned about design systems, grids, and best practices in college, this experience made me fully appreciate their practical importance. I discovered more efficient ways to hand off designs, from considering responsive design to clearly specifying details and maintaining thorough documentation.</p>
                            <p class = "section-subtitle">Growing Within a Skilled Design Team</p>
                            <p class = "body-text">It was my first time working with a whole design team, and especially a senior UX designer. I feel that I was so lucky to have the opportunity to work with such supportive, passionate people. At first, I started out by following their guidance and imitating how they work. However, as the internship progressed, I began to internalize these practices, and was able to actively contribute. I not only completed assigned tasks, but also found opportunities for improvement and proposed new ideas.</p>
                        </div>
                    </section>
                </div>
            </div>
            <div class = "project-footer">
                <div class = "project-footer-top">
                    <div class = "project-footer-previous">
                        <p class = "project-footer-subtitle">MHCI X University Hospitals of Cleveland</p>
                        <p class = "project-footer-title">Bridging the Gap Between Clinic &amp; At-home Wound Care</p>
                        <button class = "read-more-button" onClick={() => navigate('/uhc')}>← Read More</button>
                    </div>
                    <div class = "project-footer-next">
                        <p class = "project-footer-subtitle">Greenify</p>
                        <p class = "project-footer-title">Guiding Eco-conscious Choices for Designers<br /><br /></p>
                        <button class = "read-more-button" onClick={() => navigate('/greenify')}>Read More →</button>
                    </div>
                </div>
                <div class = "divider"></div>
            </div>
        </div>
  );

}

export default Pivo;