import React, { useState, useRef } from 'react';
import './VideoList.css';
import video1 from './assets/video/Sequence01.mov';
import video2 from './assets/video/Sequence02.mp4';
import video3 from './assets/video/Sequence03.mp4';
import video4 from './assets/video/Sequence04.mp4';
import image1 from './assets/img/album-img1.png';
import image2 from './assets/img/album-img2.jpeg';
import image3 from './assets/img/album-img3.png';
import image4 from './assets/img/album-img4.png';

const videos = [
  { id: 1, videoUrl: video1, title: 'The Less I Know The Better', artist: 'Tame Impala', albumImage: image1 },
  { id: 2, videoUrl: video2, title: 'GOT MY NUMBER', artist: 'MONSTA X', albumImage: image2 },
  { id: 3, videoUrl: video3, title: 'MAVERICK', artist: 'THE BOYZ', albumImage: image3 },
  { id: 4, videoUrl: video4, title: 'everything i wanted', artist: 'Billie Eilish', albumImage: image4 },
];

const VideoList = () => {
  const [playingVideo, setPlayingVideo] = useState(null);
  const videoRefs = useRef([]);
  const videoListRef = useRef(null); // This is the reference to the video list container

  const itemWidth = 300; // Width of each video item
  const gap = 20; // Gap between video items
  const scrollAmount = itemWidth + gap; // Total scroll amount including the gap

  // Handle video play
  const handlePlay = (id) => {
    setPlayingVideo(id);
    const currentVideo = videoRefs.current[id - 1]; // Adjust index for zero-based array
    if (currentVideo) {
      currentVideo.muted = false; // Unmute the video when it plays
      currentVideo.controls = true; // Show controls when playing
      // Show gradient overlay when the video is playing
      const overlay = document.querySelector(`.video-item:nth-child(${id}) .gradient-overlay`);
      if (overlay) {
        overlay.classList.add('show');
      }
    }
  };

  // Handle video pause
  const handlePause = (id) => {
    const currentVideo = videoRefs.current[id - 1]; // Adjust index for zero-based array
    if (currentVideo) {
      currentVideo.controls = false; // Hide controls when paused
      // Hide gradient overlay when the video is paused
      const overlay = document.querySelector(`.video-item:nth-child(${id}) .gradient-overlay`);
      if (overlay) {
        overlay.classList.remove('show');
      }
    }
    setPlayingVideo(null);
  };

  // Handle mouse hover to show controls and gradient overlay
  const handleMouseEnter = (id) => {
    const currentVideo = videoRefs.current[id - 1]; // Adjust index for zero-based array
    if (currentVideo) {
      currentVideo.controls = true; // Show controls on hover
    }
    // Show gradient overlay on hover
    const overlay = document.querySelector(`.video-item:nth-child(${id}) .gradient-overlay`);
    if (overlay) {
      overlay.classList.add('show');
    }
  };

  // Handle mouse leave to hide controls and gradient overlay if not playing
  const handleMouseLeave = (id) => {
    const currentVideo = videoRefs.current[id - 1]; // Adjust index for zero-based array
    if (currentVideo && playingVideo !== id) {
      currentVideo.controls = false; // Hide controls when not playing and not hovering
    }
    // Hide the gradient overlay if the video is not playing
    const overlay = document.querySelector(`.video-item:nth-child(${id}) .gradient-overlay`);
    if (overlay && playingVideo !== id) {
      overlay.classList.remove('show');
    }
  };

  // Handle navigation (scroll left)
  const handleScrollLeft = () => {
    if (videoListRef.current) {
      const newScrollPosition = videoListRef.current.scrollLeft - scrollAmount; // Calculate the new scroll position
      videoListRef.current.scrollTo({
        left: newScrollPosition,
        behavior: 'smooth',
      });
    }
  };

  // Handle navigation (scroll right)
  const handleScrollRight = () => {
    if (videoListRef.current) {
      const newScrollPosition = videoListRef.current.scrollLeft + scrollAmount; // Calculate the new scroll position
      videoListRef.current.scrollTo({
        left: newScrollPosition,
        behavior: 'smooth',
      });
    }
  };

  return (
    <div className="video-list-container">
      <div className="video-list" ref={videoListRef}>
        {videos.map((video) => (
          <div
            key={video.id}
            className="video-item"
            onMouseEnter={() => handleMouseEnter(video.id)}
            onMouseLeave={() => handleMouseLeave(video.id)}
          >
            <video
              ref={(el) => (videoRefs.current[video.id - 1] = el)} // Store video refs
              src={video.videoUrl}
              playsInline
              className={`video ${playingVideo === video.id ? 'playing' : ''}`}
              onPlay={() => handlePlay(video.id)}
              onPause={() => handlePause(video.id)}
            />
            <div className="gradient-overlay" /> {/* Black gradient overlay */}
            {/* Overlay for title and album image */}
            <div class = {`overlay ${playingVideo === video.id ? 'show' : ''}`}>
              <img src = {video.albumImage} alt={`${video.title} album cover`} className="album-image" />
              <div class = "song-details">
                <p class = "song-title">{video.title}</p>
                <p class = "song-artist">{video.artist}</p>
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className="video-list-controls">
        <button className="control-btn" onClick={handleScrollLeft}>{'<'}</button>
        <button className="control-btn" onClick={handleScrollRight}>{'>'}</button>
      </div>
    </div>
  );
};

export default VideoList;
