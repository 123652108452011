import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './Home.css';
import Toggle from './Toggle';
import workImg1 from './assets/img/work-img1.png';
import workImg2 from './assets/img/work-img2.png';
import workImg3 from './assets/img/work-img3.png';
import workImg4 from './assets/img/work-img4.png';
import workImg5 from './assets/img/work-img5.png';
import workImg6 from './assets/img/work-img6.png';

function Home() {

    const [theme, setTheme] = useState('dark');
    const navigate = useNavigate();

    useEffect(() => {
        document.documentElement.setAttribute('data-theme', theme);
    }, [theme]);

    const toggleTheme = () => {
        setTheme(theme === 'dark' ? 'light' : 'dark');
    };

    return (
        <div class = "main">
            <div class = "hero">
                <div class = "hero-right">
                    <p>Product designer based in Pittsburgh. Previously studied Master of Human-Computer Interaction at Carnegie Mellon University. Founder and designer for a reward-based study timer app <a class = "link" href = "https://www.focusplanet.app/" target="_blank" rel="noopener noreferrer">Focus Planet ↗</a>. Passionate about sustainable design and entrepreneurship.</p>
                </div>
                <div class = "hero-left">
                    <p>This website could save up to 47% of energy when in dark mode. (<a href="https://dl.acm.org/doi/pdf/10.1145/3458864.3467682" class = "link" target="_blank" rel="noopener noreferrer">A Purdue Study ↗</a>) To read more about how this interface has been designed to to promote sustainability, <a class = "link" onClick={() => navigate('/greenify#reflection')}>click here ↗</a>.</p>
                    <div id = "toggle-button">
                        <Toggle toggleTheme={toggleTheme} isDarkMode={theme === 'dark'} />
                    </div>
                </div>
            </div>
            <div class = "works-list">
                <div class ="works-list-row" id = "works-list-row-1">
                    <div class = "work-item" id = "work-item-FocusPlanet" onClick={() => navigate('/focusplanet')}>
                        <div class = "work-img">
                            <img src={workImg1} alt="Focus Planet Thumbnail" />
                        </div>
                        <p class = "work-subtitle">Focus Planet</p>
                        <p class = "work-title">Launching Focus Planet: From Idea to a Startup</p>
                    </div>
                    <div class = "work-item" id = "work-item-Subway" onClick={() => navigate('/subway')}>
                        <div class = "work-img">
                            <img src={workImg6} alt="Senior Thesis Thumbnail" />
                        </div>
                        <p class = "work-subtitle">Case Study</p>
                        <p class = "work-title">Reducing Seat-Finding Frustrations in the Subway</p>
                    </div>
                    <div class = "work-item" id = "work-item-Pivo" onClick={() => navigate('/pivo')}>
                        <div class = "work-img">
                            <img src={workImg3} alt="Pivo Thumbnail" />
                        </div>
                        <p class = "work-subtitle">Internship</p>
                        <p class = "work-title">Designing at the Intersection of Hardware and Software</p>
                    </div>
                </div>
                <div class ="works-list-row" id = "works-list-row-2">
                <div class = "work-item" id = "work-item-Greenify" onClick={() => navigate('/greenify')}>
                        <div class = "work-img">
                            <img src={workImg4} alt="Greenify Thumbnail" />
                        </div>
                        <p class = "work-subtitle">Sustainable Design</p>
                        <p class = "work-title">Guiding Eco-conscious Choices for Designers</p>
                    </div>
                    <div class = "work-item" id = "work-item-GenSys" onClick={() => navigate('/gen-sys')}>
                        <div class = "work-img">
                            <img src={workImg5} alt="Generative Systems for Design Thumbnail" />
                        </div>
                        <p class = "work-subtitle">Generative Systems for Design</p>
                        <p class = "work-title">Animating Static Album Covers to Bring Music to the Deaf</p>
                    </div>
                    <div class = "work-item" id = "work-item-UHC" onClick={() => navigate('/uhc')}>
                        <div class = "work-img">
                            <img src={workImg2} alt="University Hospitals of Cleveland Thumbnail" />
                        </div>
                        <p class = "work-subtitle">MHCI X University Hospitals of Cleveland</p>
                        <p class = "work-title">Bridging the Gap Between Clinic &amp; At-home Wound Care</p>
                    </div>
                </div>
            </div>
        </div>
  );

}

export default Home;