import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { animateScroll as scroll} from 'react-scroll';
import './FocusPlanet.css';
import Function from './Function';
import headerImg from './assets/img/FocusPlanet/focusplanet-header-img.png';
import Image1 from './assets/img/FocusPlanet/timer.png';
import Image2 from './assets/img/FocusPlanet/home.png';
import Image3 from './assets/img/FocusPlanet/planetpass.png';
import Image4 from './assets/img/FocusPlanet/reward.png';
import Image5 from './assets/img/FocusPlanet/crew.png';
import Image6 from './assets/img/FocusPlanet/report.png';
import BrandLogoDark from './assets/img/FocusPlanet/brand-identity-dark.png';
import BrandLogoLight from './assets/img/FocusPlanet/brand-identity-light.png';
import LogoVariations from './assets/img/FocusPlanet/logo-variations.png';
import BrandGuideDark from './assets/img/FocusPlanet/brand-guide-dark.png';
import BrandGuideLight from './assets/img/FocusPlanet/brand-guide-light.png';
import BoothImg from './assets/img/FocusPlanet/promo-booth-img.png';
import SNSImg from './assets/img/FocusPlanet/sns-img.png';


function FocusPlanet() {
  
    const [theme, setTheme] = useState('dark');
    const [atBottom, setAtBottom] = useState(false);
    const [activeSection, setActiveSection] = useState('');
    const navigate = useNavigate();
    const location = useLocation();
  
    useEffect(() => {
      document.documentElement.setAttribute('data-theme', theme);
    }, [theme]);
  
    const setLightMode = () => {
      setTheme('light');
    };
  
    const setDarkMode = () => {
      setTheme('dark');
    };
  
    useEffect(() => {
      const handleScroll = () => {
        const sections = ['#project-summary', '#introduction', '#impact', '#funding', '#marketing', '#branding'];
  
        let currentSection = '';
  
        sections.forEach(section => {
          const element = document.querySelector(section);
          const rect = element.getBoundingClientRect();
  
          // Detect when the section is in the viewport, at least partially visible
          if (rect.top >= 0 && rect.bottom <= window.innerHeight) {
            currentSection = section;
          } else if (rect.top < window.innerHeight && rect.bottom >= 0) {
            currentSection = section;
          }
        });
  
        setActiveSection(currentSection);
  
        // Check if user is at the bottom of the page
        if (window.innerHeight + window.pageYOffset >= document.body.offsetHeight) {
          setAtBottom(true);
        } else {
          setAtBottom(false);
        }
      };
  
      window.addEventListener('scroll', handleScroll);
      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }, []);
  
    const scrollToTop = () => {
      scroll.scrollToTop({
        duration: 400,
        smooth: 'easeInOutQuart',
      });
    };
  
    useEffect(() => {
      const hash = location.hash;
      if (hash) {
        const element = document.querySelector(hash);
        if (element) {
          const offset = 40;
          const elementPosition = element.getBoundingClientRect().top + window.pageYOffset;
          const offsetPosition = elementPosition - offset;
  
          window.scrollTo({
            top: offsetPosition,
            behavior: 'smooth',
          });
        }
      }
    }, [location]);
  
    return (
      <div>
        <div class = "body">
            <div class = "left-column">
                <nav>
                <ul class = {`shortcut-menu ${atBottom ? 'hide' : 'show'}`}>
                    <li class = {`left-column-list-text ${activeSection === '#project-summary' ? 'active' : ''}`}>
                        <Link to = "/focusplanet#project-summary" smooth={true} duration={500}>
                            project summary ↘
                        </Link>
                    </li>
                    <li class = {`left-column-list-text ${activeSection === '#introduction' ? 'active' : ''}`}>
                        <Link to = "/focusplanet#introduction" smooth={true} duration={500}>
                            what is focus planet? ↘
                        </Link>
                    </li>
                    <li class = {`left-column-list-text ${activeSection === '#impact' ? 'active' : ''}`}>
                        <Link to = "/focusplanet#impact" smooth={true} duration={500}>
                            impact ↘
                        </Link>
                    </li>
                    <li class = {`left-column-list-text ${activeSection === '#funding' ? 'active' : ''}`}>
                        <Link to = "/focusplanet#funding" smooth={true} duration={500}>
                            funding ↘
                        </Link>
                    </li>
                    <li class = {`left-column-list-text ${activeSection === '#marketing' ? 'active' : ''}`}>
                        <Link to = "/focusplanet#marketing" smooth={true} duration={500}>
                            marketing ↘
                        </Link>
                    </li>
                    <li class = {`left-column-list-text ${activeSection === '#branding' ? 'active' : ''}`}>
                        <Link to = "/focusplanet#branding" smooth={true} duration={500}>
                            branding ↘
                        </Link>
                    </li>
                </ul>
                {atBottom && (
                    <ul class = "shortcut-menu show">
                    <li class = "left-column-list-text" onClick={scrollToTop} style={{ cursor: 'pointer' }}>
                        Go to top ↑
                    </li>
                    </ul>
                )}
                </nav>
                <div className="theme-toggle">
                <span
                    onClick={setDarkMode}
                    style={{
                    cursor: 'pointer',
                    fontWeight: theme === 'dark' ? 'bold' : 'normal',
                    color: theme === 'dark' ? 'inherit' : '#929292',
                    }}
                >
                    ⏾ DARK
                </span>
                <span style={{ margin: '0 10px' }}> / </span>
                <span
                    onClick={setLightMode}
                    style={{
                    cursor: 'pointer',
                    fontWeight: theme === 'light' ? 'bold' : 'normal',
                    color: theme === 'light' ? 'inherit' : '#929292',
                    }}
                >
                    ☼ LIGHT
                </span>
                </div>
            </div>
            <div class = "main-content">
                <p class = "project-subtitle" id = "project-summary">Focus Planet</p>
                <h1 class = "project-title">Launching Focus Planet: From Idea to a Startup</h1>
                <div class = "header-img">
                    <img src={headerImg} alt="Focus Planet Header Image" />
                </div>
                <p class = "project-description">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book</p>
                <div class = "project-tag-list">
                    <div class = "project-tag-row-1">
                        <div class = "project-tag-item" id = "timeline">
                            <p class = "tag-name">TIMELINE</p>
                            <p class = "tag-description">2+ years<br></br>(April 2022 – Present)</p>
                        </div>
                        <div class = "project-tag-item" id = "role">
                            <p class = "tag-name">ROLE</p>
                            <p class = "tag-description">Founder &amp;<br></br>Product Designer <br></br> Contributed to Product Strategy, Branding and Marketing </p>
                        </div>
                    </div>
                    <div class = "project-tag-row-2">
                        <div class = "project-tag-item" id = "collaborators">
                            <p class = "tag-name">COLLABORATORS</p>
                            <p class = "tag-description">1 Project Manager<br></br>2 Frontend Developers<br></br>1 Backend Developer</p>
                        </div>
                        <div class = "project-tag-item" id = "tools">
                            <p class = "tag-name">TOOLS</p>
                            <p class = "tag-description">Figma<br></br>Adobe Premiere Pro<br></br>Illustrator</p>
                        </div>
                    </div>
                    <div class = "project-tag-row-3" id = "button-wrapper">
                        <a href="https://www.focusplanet.app" target="_blank" rel="noopener noreferrer">
                            <button class = "button-text">Landing Page →</button>
                        </a>
                        <a href="https://apps.apple.com/kr/app/%ED%8F%AC%EC%BB%A4%EC%8A%A4%ED%94%8C%EB%9E%98%EB%8B%9B-%EB%8F%88%EB%B2%84%EB%8A%94-%EC%A7%91%EC%A4%91%EC%8B%9C%EA%B0%84-%EC%95%B1%ED%85%8C%ED%81%AC/id1597905981" target="_blank" rel="noopener noreferrer">
                            <button class = "button-text">Download App →</button>
                        </a>
                    </div>
                </div>
                <section class = "content-section" id = "introduction">
                    <p class = "content-tag">01 WHAT IS FOCUS PLANET?</p>
                    <div class = "section-content">
                        <p class = "section-subtitle">"Focus Planet is a study-based reward app that converts users' study time into cashable rewards."</p>
                        <div class = "function-wrapper">
                            <Function />
                        </div>
                        <div class = "function-mobile">
                            <div class = "function-mobile-item">
                                <img class = "function-mobile-img" src = {Image1}/>
                                <p class = "section-subtitle" id = "function-mobile-title">Study Timer</p>
                                <p class = "body-text" id = "function-mobile-subtext">Users earn 1 star per minute when they track their focus time with Focus Planet.</p>
                            </div>
                            <div class = "function-mobile-item">
                                <img class = "function-mobile-img" src = {Image2}/>
                                <p class = "section-subtitle" id = "function-mobile-title">Home</p>
                                <p class = "body-text" id = "function-mobile-subtext">Users earn 1 star per minute when they track their focus time with Focus Planet.</p>
                            </div>
                            <div class = "function-mobile-item">
                                <img class = "function-mobile-img" src = {Image3}/>
                                <p class = "section-subtitle" id = "function-mobile-title">Planet Pass</p>
                                <p class = "body-text" id = "function-mobile-subtext">After 1 hour of continuous focus, users get a chance to enter a random bonus reward draw.</p>
                            </div>
                            <div class = "function-mobile-item">
                                <img class = "function-mobile-img" src = {Image4}/>
                                <p class = "section-subtitle" id = "function-mobile-title">Shop</p>
                                <p class = "body-text" id = "function-mobile-subtext">Points can be used in the reward shop to purchase gift cards, just like cash.</p>
                            </div>
                            <div class = "function-mobile-item">
                                <img class = "function-mobile-img" src = {Image5}/>
                                <p class = "section-subtitle" id = "function-mobile-title">Crew</p>
                                <p class = "body-text" id = "function-mobile-subtext">Users compete and achieve goals together with friends in study crews.</p>
                            </div>
                            <div class = "function-mobile-item">
                                <img class = "function-mobile-img" src = {Image6}/>
                                <p class = "section-subtitle" id = "function-mobile-title">Stats</p>
                                <p class = "body-text" id = "function-mobile-subtext">In the Stats tab, users can review their daily, weekly, and monthly study patterns.</p>
                            </div>
                        </div>
                    </div>
                </section>
                <section class = "content-section" id = "impact">
                    <p class = "content-tag">02 IMPACT</p>
                    <div class = "section-content">
                        <div class = "metrics-box-wrapper">
                            <div class = "metrics-box-item">
                                <p class = "box-accent-text">$30K</p>
                                <div class = "divider"></div>
                                <p class = "box-text" id = "metrics-box-text">Acquired funding from the Korean Ministry of SMEs and Startups</p>
                            </div>
                            <div class = "metrics-box-item">
                                <p class = "box-accent-text">2700+</p>
                                <div class = "divider"></div>
                                <p class = "box-text" id = "metrics-box-text">Monthly Active Users <br></br>(2024.09-2024.10)</p>
                            </div>
                            <div class = "metrics-box-item">
                                <p class = "box-accent-text">#47</p>
                                <div class = "divider"></div>
                                <p class = "box-text" id = "metrics-box-text">Peak ranking in the Education Category on the Korean App Store</p>
                            </div>
                        </div>
                    </div>
                </section>
                <section class = "content-section" id = "funding">
                    <p class = "content-tag">04 FUNDING</p>
                    <div class = "section-content">
                      <p class = "section-subtitle">The Merchandise Venture</p>
                      <p class = "body-text">One of the most persistent challenges we faced in our journey was securing funding. In the early stages, when our app was barely generating any revenue, our initial solution was to tap into external sources of income by selling custom merch. We sold space-themed paperweights on a crowdfunding site called Tumblebug, which helped us raise around $2,000 in profit. However, we quickly realized that relying on merchandise sales wasn’t sustainable in the long run. </p>
                      <p class = "section-subtitle">Securing Government Funding</p>
                      <p class = "body-text">Recognizing this limitation, we shifted our focus to government-funded opportunities, applying for a Pre-Startup Package. After a screening process that included submitting a business proposal and delivering a pitch, we were selected and received a total of $30K in funding from May to December, 2023. This helped us cover our operational costs and allowed us to explore new opportunities, like running online marketing campaigns. During this time, in June 2023, we also took a significant step by officially registering our business.</p>
                    </div>
                </section>
                <section class = "content-section" id = "marketing">
                    <p class = "content-tag">05 MARKETING</p>
                    <div class = "section-content">
                      <p class = "body-text">When we launched our app, we quickly realized that getting it on the App Store was just the first step. Without a dedicated marketing role on our team, driving customer engagement became one of our major challenges.</p>
                      <p class = "section-subtitle">Running Promotional Booths</p>
                      <p class = "body-text">Initially, we focused on one-off promotional events, like running promotional booths. As university students, we took opportunities to introduce our product at university fairs, visiting Kyunghee University and Yonsei University. To draw in the crowd, we handed out glow stick bracelets to anyone who downloaded the app, and the response was overwhelmingly positive—students loved the concept, and the booth generated genuine interest.</p>
                      <p class = "body-text">Later, we landed spots at larger gatherings like the Sinchon Global Culture Festival and Insiders Demo Day. There, we introduced lucky draw events, where people could earn a chance to win custom merch, like sticky notes, phone grips, and paperweights if they downloaded the app.</p>
                      <img class = "marketing-img" src={BoothImg}/>
                      {/*<div class = "marketing-body-wrapper">
                        <img class = "booth-img" src = {BoothImg1}/>
                        <p class = "body-text" id = "marketing-body">Initially, we focused on one-off promotional events, like running promotional booths. As university students, we took opportunities to introduce our product at university fairs, visiting Kyunghee University and Yonsei University. To draw in the crowd, we handed out glow stick bracelets to anyone who downloaded the app, and the response was overwhelmingly positive—students loved the concept, and the booth generated genuine interest.</p>
                      </div>
                      <div class = "marketing-body-wrapper">
                        <p class = "body-text" id = "marketing-body">Initially, we focused on one-off promotional events, like running promotional booths. As university students, we took opportunities to introduce our product at university fairs, visiting Kyunghee University and Yonsei University. To draw in the crowd, we handed out glow stick bracelets to anyone who downloaded the app, and the response was overwhelmingly positive—students loved the concept, and the booth generated genuine interest.</p>
                        <img class = "booth-img" src = {BoothImg2}/>
                      </div>*/}
                      <p class = "section-subtitle">Social Media Marketing</p>
                      <p class = "body-text">Without a professional marketer on the team, the product manager and I decided to try our hands at social media marketing. Our primary users were women in their teens and twenties, so we focused on platforms they frequented, such as Instagram, Facebook, and TikTok.</p>
                      <p class = "body-text">We created relatable study motivation contents and visually engaging advertisements that resonated with this demographic, and to our surprise, it worked far better than we’d anticipated. Our campaigns attracted traffic to our landing page at as low as just $0.10 per visit. </p>
                      <img class = "marketing-img" src={SNSImg}/>
                    </div>
                </section>
                <section class = "content-section" id = "branding">
                    <p class = "content-tag">06 BRANDING</p>
                    <div class = "section-content">
                      <p class = "body-text">As we shifted to the social media marketing stage, I led a complete rebranding to improve Search Engine Optimization (SEO) and establish a cohesive brand identity across the app interface, printed materials, and social media. The minimalist design emphasizes focus and clarity, aligning with the Focus Planet’s mission to motivate students by delivering a truly "rewarding" study experience. Its dark, space-themed aesthetic, with calming shades of blue, also reduces battery consumption, ensuring energy efficiency for prolonged screen use.</p>
                      <p class = "image-subtext" id = "logo-image-subtext">Logo</p>
                      <img class = "branding-img" src={theme === 'light' ? BrandLogoLight : BrandLogoDark}/>
                      <p class = "image-subtext">Logo Variations</p>
                      <img class = "branding-img" src = {LogoVariations}/>
                      <p class = "image-subtext">Brand Guidelines</p>
                      <img class = "branding-img" src={theme === 'light' ? BrandGuideLight : BrandGuideDark}/>
                    </div>
                </section>
                <section class = "content-section" id = "reflection">
                    <p class = "content-tag">07 REFLECTION</p>
                    <div class = "section-content">
                      <p class = "body-text">I created a cohesive brand image for Focus Planet across the app interface, printed materials, and social media. The minimalist design emphasizes focus and clarity, aligning with the Focus Planet’s mission to motivate students by delivering a truly "rewarding" study experience. Its dark, space-themed aesthetic, with calming shades of blue, also reduces battery consumption, ensuring energy efficiency for prolonged screen use.</p>
                    </div>
                </section>
            </div>
            </div>
            <div class = "project-footer">
                <div class = "project-footer-top">
                    <div class = "project-footer-previous">
                        <p class = "project-footer-subtitle">Senior Thesis</p>
                        <p class = "project-footer-title">Reducing Seat-Finding Frustrations in the Subway</p>
                        <button class = "read-more-button" onClick={() => navigate('/subway')}>← Read More</button>
                    </div>
                    <div class = "project-footer-next">
                        <p class = "project-footer-subtitle">MHCI X University Hospitals of Cleveland</p>
                        <p class = "project-footer-title">Bridging the Gap Between Clinic &amp; At-home Wound Care</p>
                        <button class = "read-more-button" onClick={() => navigate('/uhc')}>Read More →</button>
                    </div>
                </div>
            <div class = "divider"></div>
            </div>
        </div>
    );

}

export default FocusPlanet;


{/*
function FocusPlanet() {
  const [theme, setTheme] = useState('dark');
  const [atBottom, setAtBottom] = useState(false);
  const [activeSection, setActiveSection] = useState('');
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    document.documentElement.setAttribute('data-theme', theme);
  }, [theme]);

  const setLightMode = () => {
    setTheme('light');
  };

  const setDarkMode = () => {
    setTheme('dark');
  };

  useEffect(() => {
    const handleScroll = () => {
      const sections = ['#project-summary', '#introduction', '#impact'];

      let currentSection = '';

      sections.forEach(section => {
        const element = document.querySelector(section);
        const rect = element.getBoundingClientRect();

        // Detect when the section is in the viewport, at least partially visible
        if (rect.top >= 0 && rect.bottom <= window.innerHeight) {
          currentSection = section;
        } else if (rect.top < window.innerHeight && rect.bottom >= 0) {
          currentSection = section;
        }
      });

      setActiveSection(currentSection);

      // Check if user is at the bottom of the page
      if (window.innerHeight + window.pageYOffset >= document.body.offsetHeight) {
        setAtBottom(true);
      } else {
        setAtBottom(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const scrollToTop = () => {
    scroll.scrollToTop({
      duration: 400,
      smooth: 'easeInOutQuart',
    });
  };

  useEffect(() => {
    const hash = location.hash;
    if (hash) {
      const element = document.querySelector(hash);
      if (element) {
        const offset = 40;
        const elementPosition = element.getBoundingClientRect().top + window.pageYOffset;
        const offsetPosition = elementPosition - offset;

        window.scrollTo({
          top: offsetPosition,
          behavior: 'smooth',
        });
      }
    }
  }, [location]);

  return (
    <div>
      <div className="body">
        <div className="left-column">
          <nav>
            <ul className={`shortcut-menu ${atBottom ? 'hide' : 'show'}`}>
              <li
                className={`left-column-list-text ${activeSection === '#project-summary' ? 'active' : ''}`}
              >
                <Link to="/focusplanet#project-summary" smooth={true} duration={500}>
                  project summary ↘
                </Link>
              </li>
              <li
                className={`left-column-list-text ${activeSection === '#introduction' ? 'active' : ''}`}
              >
                <Link to="/focusplanet#introduction" smooth={true} duration={500}>
                  what is focus planet? ↘
                </Link>
              </li>
              <li
                className={`left-column-list-text ${activeSection === '#impact' ? 'active' : ''}`}
              >
                <Link to="/focusplanet#impact" smooth={true} duration={500}>
                  impact ↘
                </Link>
              </li>
            </ul>
            {atBottom && (
              <ul className="shortcut-menu show">
                <li className="left-column-list-text" onClick={scrollToTop} style={{ cursor: 'pointer' }}>
                  Go to top ↑
                </li>
              </ul>
            )}
          </nav>
          <div className="theme-toggle">
            <span
              onClick={setDarkMode}
              style={{
                cursor: 'pointer',
                fontWeight: theme === 'dark' ? 'bold' : 'normal',
                color: theme === 'dark' ? 'inherit' : '#929292',
              }}
            >
              ⏾ DARK
            </span>
            <span style={{ margin: '0 10px' }}> / </span>
            <span
              onClick={setLightMode}
              style={{
                cursor: 'pointer',
                fontWeight: theme === 'light' ? 'bold' : 'normal',
                color: theme === 'light' ? 'inherit' : '#929292',
              }}
            >
              ☼ LIGHT
            </span>
          </div>
        </div>
        <div class = "main-content">
            <p class = "project-subtitle" id = "project-summary">Focus Planet</p>
            <h1 class = "project-title">Building a Product to Starting a Startup</h1>
            <div class = "header-img">
                <img src={headerImg} alt="Focus Planet Header Image" />
            </div>
            <p class = "project-description">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book</p>
            <div class = "project-tag-list">
                <div class = "project-tag-row-1">
                    <div class = "project-tag-item" id = "timeline">
                        <p class = "tag-name">TIMELINE</p>
                        <p class = "tag-description">2+ years<br></br>(April 2022 – Present)</p>
                    </div>
                    <div class = "project-tag-item" id = "role">
                        <p class = "tag-name">ROLE</p>
                        <p class = "tag-description">Founder &amp;<br></br>Product Designer <br></br> Contributed to Product Strategy, Branding and Marketing </p>
                    </div>
                </div>
                <div class = "project-tag-row-2">
                    <div class = "project-tag-item" id = "collaborators">
                        <p class = "tag-name">COLLABORATORS</p>
                        <p class = "tag-description">1 Project Manager<br></br>2 Frontend Developers<br></br>1 Backend Developer</p>
                    </div>
                    <div class = "project-tag-item" id = "tools">
                        <p class = "tag-name">TOOLS</p>
                        <p class = "tag-description">Figma<br></br>Adobe Premiere Pro<br></br>Illustrator</p>
                    </div>
                </div>
                <div class = "project-tag-row-3" id = "button-wrapper">
                    <a href="https://www.focusplanet.app" target="_blank" rel="noopener noreferrer">
                        <button class = "button-text">Landing Page →</button>
                    </a>
                    <a href="https://apps.apple.com/kr/app/%ED%8F%AC%EC%BB%A4%EC%8A%A4%ED%94%8C%EB%9E%98%EB%8B%9B-%EB%8F%88%EB%B2%84%EB%8A%94-%EC%A7%91%EC%A4%91%EC%8B%9C%EA%B0%84-%EC%95%B1%ED%85%8C%ED%81%AC/id1597905981" target="_blank" rel="noopener noreferrer">
                        <button class = "button-text">Download App →</button>
                    </a>
                </div>
            </div>
            <section class = "content-section" id = "introduction">
                <p class = "content-tag">01 WHAT IS FOCUS PLANET?</p>
                <div class = "section-content">
                    <p class = "section-subtitle">"Focus Planet is a study-based reward app that converts users' study time into cashable rewards."</p>
                    <div class = "function-wrapper">
                        <Function />
                    </div>
                    <div class = "function-mobile">
                        <div class = "function-mobile-item">
                            <img class = "function-mobile-img" src = {Image1}/>
                            <p class = "section-subtitle" id = "function-mobile-title">Study Timer</p>
                            <p class = "body-text" id = "function-mobile-subtext">Users earn 1 star per minute when they track their focus time with Focus Planet.</p>
                        </div>
                        <div class = "function-mobile-item">
                            <img class = "function-mobile-img" src = {Image2}/>
                            <p class = "section-subtitle" id = "function-mobile-title">Home</p>
                            <p class = "body-text" id = "function-mobile-subtext">Users earn 1 star per minute when they track their focus time with Focus Planet.</p>
                        </div>
                        <div class = "function-mobile-item">
                            <img class = "function-mobile-img" src = {Image3}/>
                            <p class = "section-subtitle" id = "function-mobile-title">Planet Pass</p>
                            <p class = "body-text" id = "function-mobile-subtext">After 1 hour of continuous focus, users get a chance to enter a random bonus reward draw.</p>
                        </div>
                        <div class = "function-mobile-item">
                            <img class = "function-mobile-img" src = {Image4}/>
                            <p class = "section-subtitle" id = "function-mobile-title">Shop</p>
                            <p class = "body-text" id = "function-mobile-subtext">Points can be used in the reward shop to purchase gift cards, just like cash.</p>
                        </div>
                        <div class = "function-mobile-item">
                            <img class = "function-mobile-img" src = {Image5}/>
                            <p class = "section-subtitle" id = "function-mobile-title">Crew</p>
                            <p class = "body-text" id = "function-mobile-subtext">Users compete and achieve goals together with friends in study crews.</p>
                        </div>
                        <div class = "function-mobile-item">
                            <img class = "function-mobile-img" src = {Image6}/>
                            <p class = "section-subtitle" id = "function-mobile-title">Stats</p>
                            <p class = "body-text" id = "function-mobile-subtext">In the Stats tab, users can review their daily, weekly, and monthly study patterns.</p>
                        </div>
                    </div>
                </div>
            </section>
            <section class = "content-section" id = "impact">
                <p class = "content-tag">02 IMPACT</p>
                <div class = "section-content">
                    <div class = "metrics-box-wrapper">
                        <div class = "metrics-box-item">
                            <p class = "box-accent-text">$28K</p>
                            <div class = "divider"></div>
                            <p class = "box-text" id = "metrics-box-text">Acquired funding from the Korean Ministry of SMEs and Startups</p>
                        </div>
                        <div class = "metrics-box-item">
                            <p class = "box-accent-text">2700+</p>
                            <div class = "divider"></div>
                            <p class = "box-text" id = "metrics-box-text">Monthly Active Users <br></br>(2024.09-2024.10)</p>
                        </div>
                        <div class = "metrics-box-item">
                            <p class = "box-accent-text">#47</p>
                            <div class = "divider"></div>
                            <p class = "box-text" id = "metrics-box-text">Peak ranking in the Education Category on the Korean App Store</p>
                        </div>
                    </div>
                </div>
            </section>
            </div>
            </div>
            <div class = "project-footer">
                <div class = "project-footer-top">
                    <div class = "project-footer-previous">
                        <p class = "project-footer-subtitle">Senior Thesis</p>
                        <p class = "project-footer-title">Reducing Seat-Finding Frustrations in the Subway</p>
                        <button class = "read-more-button" onClick={() => navigate('/subway')}>← Read More</button>
                    </div>
                    <div class = "project-footer-next">
                        <p class = "project-footer-subtitle">MHCI X University Hospitals of Cleveland</p>
                        <p class = "project-footer-title">Bridging the Gap Between Clinic &amp; At-home Wound Care</p>
                        <button class = "read-more-button" onClick={() => navigate('/uhc')}>Read More →</button>
                    </div>
                </div>
            <div class = "divider"></div>
            </div>
        </div>
  );
}

export default FocusPlanet;

*/}